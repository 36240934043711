@mixin container-base {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #eee;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .card {
    margin-bottom: 15px;
  }
}

$brand-colour: #c23149;
