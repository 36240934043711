@use './scss/main';

body {
  position: absolute;
  width: 100%;
  height: 120%;
  background-color: #000;
  margin: 0;
  padding: 0;
  top: 0px;
  left: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  letter-spacing: 0px;
  // overflow: hidden !important;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  color: #C6B168;
  a {
    color: inherit;
    text-decoration: none;
  }

  .legal-footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
    height: 30px;
    color: rgb(121, 121, 121);
    z-index: 10;
    text-align: center;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    @media only screen and (max-width: 900px) {
      height: 15px;
    }
  }
}
