@import './../../scss/main';

.main-container {
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: none;

  .click-area {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    opacity: 1;
    left: 0px;
    top: 0px;
    cursor: all;
    pointer-events: none;
  }

  .show-tracker {
    // opacity: 1 !important;
    pointer-events: all;
    cursor: all;
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  #arrow-tracker {
    position: absolute;
    transition: transform 0.5s, background-color 0.1s;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 45%;
    background-image: url('../../assets/pointer.png');
    transform: rotate(-15deg);
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -12px;
    margin-top: -12px;
    span {
      position: relative;
      text-align: center;
      bottom: -35px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      width: auto;
      left: 20px;
      transform: rotate(-30deg);
      display: none !important;
      margin: 0 auto;
      padding: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      letter-spacing: 1px;
    }
  }

  .pointer-tracker {
    background-color: unset !important;
    background-image: url('../../assets/arrow.png') !important;
  }


  .key-tracker {
    background-color: unset !important;
    background-image: url('../../assets/key.png') !important;
    transform: rotate(-350deg) !important;
    background-size: 60% !important;
  }

  .tracker-down {
    transform: rotate(-150deg) !important;
    background-color: rgba(0, 0, 0, 1) !important;
    border: 1px solid #F1DC8D;
    background-size: 50% !important;
  }

  #arrow-tracker.tracker-down span {
    transform: rotate(150deg) !important;
    display: flex !important;
  }

  .tracker-up {
    transform: rotate(29deg) !important;
    background-color: rgba(0, 0, 0, 1) !important;
    border: 1px solid #F1DC8D;
    background-size: 50% !important;
  }

  #arrow-tracker.tracker-up span {
    display: flex !important;
  }

  .tracker-down.key-tracker {
    transform: rotate(-360deg) !important;
  }

  .hide-arrow-trackers {
    // background-image: url('../../assets/key.png');
    // opacity: 1;
    cursor: none;
    pointer-events: none;
  }
}


.main-container.enable-cursor {
  // cursor: default;
  .show-tracker {
    opacity: 1 !important;
    pointer-events: all;
    cursor: none !important;
  }
}

.loading-assets {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #000;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-spinner {
    position: relative;
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-image: url('../../assets/loading.png');
    margin-left: 2px;
    margin-top: 1px;
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

ul.navigation {
  position: fixed;
  right: -100px;
  top: 0px;
  width: 60px;
  height: 100%;
  z-index: 2000;
  // background-color: #000;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  opacity: 0;
  
  li {
    position: relative;
    width: auto;
    // margin-bottom: 15px;
    // cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // border: 2px solid rgb(48, 48, 48);
    text-shadow: 1px 1px 12px #000;
    color: #fff;
    font-weight: 500;
    text-align: right;
    transition: 0.2s;
    padding-top: 12px;
    padding-bottom: 2px;
    letter-spacing: 1px;
    margin-right: 10px;
    margin-bottom: 4px;
    border-bottom: 1px solid #f1db8d00;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  li:hover {
    color: #F1DC8D;
  }

  .active {
    color: #F1DC8D;
    border-bottom: 1px solid #F1DC8D;
  }
}

ul.navigation.show-nav {
  opacity: 1;
  right: 15px;
  transition-delay: 0.5s;
  @media only screen and (max-width: 900px) {
    right: 0px;
  }
}

@media only screen and (max-width: 900px) {
  ul.navigation {
    width: 160px;
    overflow: hidden;
    justify-content: flex-start;
    right: -200px;
    top: 10px;
    // background-color: #000;
    li {
      padding: 2px 8px;
      font-size: 11px;
      right: 0px;
      border-radius: 2px;
      margin-bottom: 6px;
      margin-top: 0px;
      border: 1px solid #5656564c;
      background-color: rgba(34, 34, 34, 0.362);
    }
    li:hover {

    }
  
    .active {
      border: 1px solid #F1DC8D;
      background-color: #342c0f;
      color: #fff;
    }
  }
}

ul.navigation.mobile-nav-list {
  display: none;
  @media only screen and (max-width: 900px) {
    display: flex;
  }
}

ul.navigation.lift-buttons {
  backdrop-filter: blur(8px);
  background-color: #05050537;
  -webkit-box-shadow:0px 0px 10px 10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
  height: auto;
  top: 0%;
  border-radius: 8px 0px 0px 8px;
  right: 0px;
  padding: 0px;
  margin: 0px;
  padding: 30px;
  flex-direction: column-reverse;

  .lift-title {
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
    right: -3px;
    font-size: 12px;
    top: 10px;
    color: #fff;
  }

  li {
    padding: 0px;
    height: 50px;
    width: 50px;
    background-color: #000;
    border: 2px solid #b09d58;
    border-radius: 50%;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 0px;    
    margin-bottom: 10px;
    color: #dcc05e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-shadow: none;

    .hover-button-info {
      position: absolute;
      left: -80px;
      text-align: right;
      color: #fff;
      font-size: 20px;
      border-radius: 4px;
      padding: 6px 12px;
      transition: 0.2s;
      opacity: 0;
      width: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      text-shadow: 1px 1px 12px #000;
      pointer-events: none;
    }
  }

  li:hover {
    background-color: #dcc05e;
    border: 2px solid #fff;
    color: #fff;
    .hover-button-info {
      left: -130px;
      opacity: 1;
    }
  }

  li.active {
    background-color: #ccb251;
    border: 2px solid #fff;
    color: #fff;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.307);
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

ul.navigation.footer-list {
  top: unset;
  bottom: 1%;
  min-width: 90px;
  width: auto;
  height: 150px;
  li {
    padding-top: 3px;
    padding-bottom: 3px;
    letter-spacing: 1px;
    margin-right: 10px;
    margin-bottom: 6px;
    border-bottom: 0px solid #f1db8d00;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
}


.video-intro-component {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: 1;
  transition: 2.8s;
  opacity: 1;
  filter: blur(8px) grayscale(100%);
  opacity: 0;
  z-index: 2;
}

.video-component {
  position: fixed;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  z-index: 1;
  transition: 2.2s;
  opacity: 0;
  filter: blur(5px) grayscale(0%);
  // transform: rotate(-12deg);
}

@keyframes pulse-video-animation {
  0% {
    transform: rotate(0deg);
    top: -53%;
  }

  33% {
    transform: rotate(-0deg);
    top: -47%;
  }

  66% {
    transform: rotate(-0deg);
    top: -50%;
  }

  100% {
    transform: rotate(0deg);
    top: -53%;
  }
}

.pulse-video {
  // animation: pulse-video-animation 15s cubic-bezier(0.4, 0.6, 0.4, 0.6) both;
  // animation-iteration-count: infinite;
  transform: rotate(0deg);
  filter: blur(5px) grayscale(100%);
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  opacity: 0.5;
}

.reveal-video {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  filter: blur(0px) grayscale(0%);
  opacity: 1;
  transform: rotate(0deg);
  @media only screen and (max-width: 900px) {
    top: -65%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
}


.hide-video {
  opacity: 0;
  pointer-events: none;
}

video {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
}

.metaverse-transition {
  opacity: 0;
  pointer-events: none;
}

.section-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #000;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    position: absolute;
    top: 0px;
    color: #fff;
    margin: 0;
    margin-top: 0px;
    padding: 0;
    font-size: 45px;
    width: 90%;
    z-index: 1000;
    transition: 0.5s;
    opacity: 0;
    left: -200px;
    background-color: #000;
    padding: 12px 30px;
    width: auto;
    border-radius: 0px 0px 4px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    letter-spacing: 1px;
  }

  .section-copy {
    position: absolute;
    bottom: 8%;
    color: #fff;
    margin: 0;
    margin-top: 0px;
    padding: 0;
    font-size: 18px;
    width: 90%;
    max-width: 250px;
    z-index: 1000;
    transition: 0.5s;
    opacity: 0;
    left: -200px;
    background-color: rgba(0, 0, 0, 0.191);
    padding: 15px;
    // padding-right: 10px;
    font-weight: 400;
    border-radius: 8px;
    line-height: 150%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-box-shadow:0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(8px);

    .info-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      left: -8px;
      top: -8px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../assets/info-icon.png');
      @media only screen and (max-width: 900px) {
        width: 20px;
        height: 20px;
      }
    }

    @media only screen and (max-width: 900px) {
      font-size: 11px;
      max-width: 250px;
      bottom: 5%;
    }
  }
}

.show-copy-section {
  left: 10px !important;
}

@media only screen and (max-width: 900px) {
  .section-overlay {  
    h2 {
      display: none;
      margin-top: 5px;
      left: 10px;
      z-index: 1000;
      font-size: 30px;
    }
  }
}

.passes-section {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: unset !important;
  color: #fff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0%;
  opacity: 0;
  margin-top: 0px;
  transition: 1.2s;
  filter: blur(20px);
  // transition-delay: 0.5s;

  .pass-container {
    position: relative;
    width: 70%;
    height: 70%;
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
    transition: all 1.2s, border 0.3s;
    // transition-delay: 0.5s;
    opacity: 1;
    -webkit-box-shadow:0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.12);
    @media only screen and (max-width: 900px) {
      width: 260px;
      height: 260px;
      min-width: 260px;
      min-height: 260px;
      margin-top: 0px;
      margin-bottom: 0px;
      top: -30px;
    }
  }

  .header-title {
    position: relative;
    font-size: 45px;
    text-align: center;
    top: 0%;
    width: 90%;
    height: auto;
    color: #f2dc8d;
    text-shadow: 1px 1px 12px #000;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media only screen and (max-width: 900px) {
      top: -10px;
      font-size: 26px !important;
    }
  }

  .header-sub-title {
    position: relative;
    font-size: 20px;
    text-align: center;
    width: 90%;
    top: 0%;
    height: auto;
    color: #f2dc8d;
    text-shadow: 1px 1px 12px #000;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media only screen and (max-width: 900px) {
      top: -30px;
      font-size: 16px !important;
    }
  }


  @keyframes pulse {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(10deg);
    }
    85% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes pulse-fast {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    65% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .header-cta {
    position: absolute;
    color: #fff;
    // cursor: pointer;
    top: 70%;
    margin-left: 260px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    border: 2px solid #F1DC8D;
    width: 60px;
    height: 60px;
    animation-name: pulse;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    background-image: url('../../assets/locked-gold.png');
    -webkit-box-shadow:0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
    z-index: 2;

    .down-arrow {
      position: absolute;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      margin-top: 10px;
      // transform: rotate(45deg);
      // left: -20px;
      bottom: -10px;
    }
    @media only screen and (max-width: 900px) {
      // display: none;
      top: 65%;
      margin-left: 135px;
      width: 45px;
      height: 45px;
    }
  }

  .header-cta:hover {
    // background-image: url('../../assets/unlocked-gold.png');
    // background-size: 60%;
  }

  .header-access-cta {
    position: relative;
    font-size: 16px;
    text-align: center;
    width: 400px;
    text-align: center;
    top: 0%;
    height: auto;
    color: #f2dc8d;
    // background-color: #000;
    text-shadow: 1px 1px 12px #000;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media only screen and (max-width: 900px) {
      top: -25px !important;
      top: 0%;
      text-align: center;
      width: 90%;
      font-size: 12px !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .header-title, .header-sub-title {
      font-size: 20px;
    }
  }
}

.animate-landing {
  opacity: 1;
  margin-top: 0px;
  filter: blur(0px);
  .pass-container {
    max-width: 460px;
    max-height: 390px;
  }
}

.grid-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  height: auto;
  width: 90%;
  max-width: 1000px;
  margin-top: -20px;

  .grid-item {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 130px;
    height: auto;
    margin: 20px;
    margin-top: 0px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;

    .grid-item-image {
      position: relative;
      width: 130px;
      height: 130px;
      min-height: 130px;
      // border-radius: 50%;
      border: 4px solid #000;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 25px;
      transition: 0.3s;
      overflow: hidden;
      video {
        position: relative;
        left: 0px;
        right: 0px;
        width: 100%;
      }
    }

    span {
      text-align: center;
      color: #fff;
      font-size: 16px;
    }

    .grid-item-image.logo {
      border-radius: 50%;
      width: 170px;
      height: 170px;
      min-height: 170px;
      @media only screen and (max-width: 900px) {
        width: 130px;
        height: 130px;
        min-height: 130px;
        margin-bottom: 15px;
      }
    }

    .decentraland {
      background-image: url('../../assets/decentraland.png');
    }

    .sandbox {
      background-image: url('../../assets/sandbox.png');
    }

    .metakey {
      background-image: url('../../assets/metakey.png');
    }

    .abg {
      background-image: url('../../assets/abg.png');
    }

    .riw {
      background-image: url('../../assets/riw.jpg');
    }

    .pellar {
      background-image: url('../../assets/pellar.png');
    }

    .elvis-logo {
      background-image: url('../../assets/elvis-logo.png');
    }

  }

  .grid-item:hover {
    .grid-item-image {
      // margin-bottom: 15px;
      // border: 4px solid rgba(255, 255, 255, 0.203);
      // border-radius: 50%;

    }
  }

}

.utility-section {
  z-index: 1000;


  .carousel-container {
    position: relative;
    width: 350px;
    height: auto;
    @media only screen and (max-width: 900px) {

    }

    ul {
      list-style-type: none;
    }

    .alice-carousel {
      position: relative;
      width: 100%;

      .alice-carousel__wrapper {
        overflow: visible;
      }

      .alice-carousel__prev-btn, .alice-carousel__next-btn {
        display: none;
        border: 2px solid #dcc05e;
        width: 45px;
        height: 45px;
        position: absolute;
        top: 40%;
        border-radius: 50%;
        background-image: url('../../assets/arrow.png');
        background-size: 40%;
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.2s;
        cursor: pointer;
        span {
          position: absolute;
          opacity: 0;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          top: 0px;
          left: 0px;
        }
      }

      .alice-carousel__prev-btn:hover, .alice-carousel__next-btn:hover {
        border: 2px solid #dcc05e;
        background-color: #b09d58;
      }

      .alice-carousel__prev-btn {
        left: -80px;
        transform: rotate(90deg);
      }

      .alice-carousel__next-btn {
        right: -80px;
        transform: rotate(-90deg);
      }

      .alice-carousel__dots-item {
        width: 10px;
        height: 10px;
        margin-top: 40px;
        background-color: #D9D9D9;
      }

      .alice-carousel__dots-item.__active
      {
        background-color: #dcc05e;
      }

      .carousel-card {
        position: relative;
        width: 340px;
        @media only screen and (max-width: 900px) {
          width: 340px;
          height: 300px;
          margin-top: -20px;
          margin-left: -10px;
        }
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .card-content {
          position: relative;
          width: 350px;
          height: 350px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          border-radius: 12px;
          @media only screen and (max-width: 900px) {
            width: 340px;
            height: 180px;
          }
          cursor: pointer;
          overflow: visible;
          video {
            position: relative;
            width: 100%;
            @media only screen and (max-width: 900px) {
              width: 250px;
              height: 200px;
              top: -20px;
            }
          }
        }
        b {
          position: relative;
          font-size: 20px;
          margin-bottom: 10px;
          letter-spacing: 1px;
          text-align: center;
          width: 90%;
          font-weight: 500;
          @media only screen and (max-width: 900px) {
            font-size: 16px;
            width: 100%;
          }
        }
        span {
          position: relative;
          width: 90%;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.8px;
          color: #fff;
          @media only screen and (max-width: 900px) {
            font-size: 12px;
          }
        }
      }

    }
  }

  .alice-carousel__stage-item {
    // opacity: 0.5;
    // filter: blur(5px);
    transition: opacity 0.5s, filter 0.5s;
  }

  .alice-carousel__stage-item.__active, .alice-carousel__stage-item:hover {
    opacity: 1 !important;
    // filter: blur(0px) !important;
  }

  .tv-utility {
    width: 180px !important;
    height: 180px !important;
    video {
      margin-top: 15px !important;
    }
    @media only screen and (max-width: 900px) {
      width: 80px !important;
      height: 90px !important;
      video {
        margin-top: 0px !important;
      }
    }
  }

  .music-utility {
    width: 240px !important;
    height: 160px !important;
    video {
      margin-top: -15px !important;
    }
    @media only screen and (max-width: 900px) {
      width: 110px !important;
      height: 90px !important;
      video {
        margin-top: -10px !important;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding-left: 16px;
  }

  .grid-container {
    opacity: 0;
    transition: 1s;
  }

  .show-grid-container {
    opacity: 1;

    .utility-header {
      position: relative;
      top: -10px;
      width: 80%;
      z-index: 2;
      height: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      @media only screen and (max-width: 900px) {
        font-size: 12px;
        line-height: 140%;
        height: 15px;
        top: -10px;
        width: 100%;
        display: none;
      }
    }

  }

  .grid-item {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 230px;
    height: auto;
    margin: 20px;
    margin-top: 0px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;

    span {
      font-size: 11px;
      font-weight: 400;
      max-width: 80%;
      line-height: 120%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none; 
      user-select: none; 
      b {
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        color:#c9b468;
      }
    }

    @media only screen and (max-width: 900px) {
      span {
        font-size: 9px;
        font-weight: 400;
        max-width: 90%;
        line-height: 120%;
        b {
          font-size: 10px;
          font-weight: 600;
          line-height: 140%;
          color:#c9b468;
        }
      }
    }

    .grid-item-image {
      position: relative;
      width: 210px;
      height: 210px;
      min-height: 210px;
      // border-radius: 50%;
      border: 4px solid #000;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 0px;
      transition: 0.3s;
      overflow: hidden;
      video {
        position: relative;
        left: 0px;
        right: 0px;
        width: 100%;
      }
    }
  }

  .grid-item-image.logo {
    border-radius: 50%;
    width: 210px;
    height: 210px;
    min-height: 210px;
    @media only screen and (max-width: 900px) {
      width: 120px;
      height: 120px;
      min-height: 120px;
      margin-bottom: 10px;
    }
  }
}


.team-section {
  .grid-container.team {
    max-width: 500px !important;
  }
}

.team-section, .partners-section {
  z-index: 1000;

  @media only screen and (min-width: 1400px) {
    .grid-container.team, .grid-container.partners {
      // max-width: 500px !important;
      margin-top: 5px;
      // margin-left: -30px;
      
      .grid-item {
        width: 210px;
        margin: 20px;
        margin-bottom: 15px;
        border-radius: 50%;
        min-width: 210px;

        span {
          // font-size: 10px;
        }
        .grid-item-image {
          width: 210px;
          height: 210px;
          min-width: 210px;
          min-height: 210px;
          margin-bottom: 10px;
        }
      }
    }

    .grid-container.partners {
      max-width: 1300px !important;
      .grid-item {
        width: 130px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .grid-container.partners {
      margin-left: -30px;
    }
    .grid-container.team, .grid-container.partners {
      max-width: 500px !important;
      margin-top: 15px;

      .grid-item {
        width: 170px;
        margin: 0;
        margin-bottom: 15px;
        border-radius: 50%;

        span {
          font-size: 10px;
        }
        .grid-item-image {
          width: 90px;
          height: 90px;
          min-height: 90px;
          margin-bottom: 10px;
        }
      }
    }

    .grid-container.partners {
      max-width: 500px !important;
      .grid-item {
        width: 100px;
      }
    }
  }
}

.utility-section {
  @media only screen and (max-width: 900px) {
    .grid-container {
      width: 100%;
      max-width: 700px;
      // margin-top: 5px;
      margin-left: -50px;
      .grid-item {
        width: 200px;
        margin: 0;
        span {
          font-size: 10px;
          margin-top: -10px;
        }
        .grid-item-image {
          width: 90px;
          height: 90px;
          min-height: 90px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .grid-item:hover {
    .grid-item-image {
      border: 4px solid #000;
    }
  }
}

@media only screen and (max-width: 900px) {
  .grid-container {
    margin-top: -20px;
    max-width: 700px;
    height: auto;
    .grid-item {
      width: 180px;
      // background-color: #b9a65f;
      margin: 3px;
      // align-items: flex-start;
      .grid-item-image {
        position: relative;
        width: 90px;
        height: 110px;
        margin-bottom: 5px;
        min-height: unset;
      }

      span {
        position: relative;
        font-size: 13px;
        // z-index: 10;
        top: 0px;
      }
    }

  }

  .partners, .team {
    .grid-item {
      width: 150px;
    }
  }

  .launch {
    margin: 0;
  }
}

.grid-container.team {
  max-width: 650px !important;
  .grid-item {
    margin-top: 20px;
  }
}

.metaverse-copy {
  position: fixed;
  font-size: 40px;
  color: #fff;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 160%;
  text-align: center;
  opacity: 0;
  margin-top: 100px;
  pointer-events: none;
  transition: 0s;
  @media only screen and (max-width: 900px) {
    font-size: 25px;
  }
}

.metaverse-copy-show {
  opacity: 1;
  margin-top: 0px;
  transition: 1.5s;
}

.hide-video-opacity {
  opacity: 0 !important;
}

.nft {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s;
  z-index: 3000;
  background-color: #000;

  .grid-item-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    video {
      position: relative;
      width: 98%;
      height: 98%;
      z-index: 0;
      background-color: #000;
      overflow: hidden;
    }  
  }

  @media only screen and (max-width: 900px) {
    width: 100px !important;
    // border: 2px solid #1e1d1a;
  }
}

.nft:hover {
  // border: 2px solid #F1DC8D;
  .header-cta {
    background-image: url('../../assets/unlocked-gold.png');
    background-size: 60%;
    background-color: rgb(159, 30, 30);
    animation-name: pulse-fast;
    animation-duration: 1s;
  }
}

.social-icon {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: #b9a65f;
  border-radius: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  z-index: 100000;
  cursor: pointer;
  transition: 0.2s;
}


.social-linktree {
  background-image: url('../../assets/linktree.png');
  background-size: 55%;
}

.social-discord {
  background-image: url('../../assets/discord.png');
  background-size: 55%;
  right: 70px;
}

.social-discord:hover {
  background-color: #c9b468;
}

.social-twitter {
  background-image: url('../../assets/twitter.png');
  right: 120px;
  background-size: 50%;
}

.faqs {
  background-image: url('../../assets/faqs.png');
  right: 171px;
  background-size: 33%;
  width: 120px !important;
}

.litepaper {
  background-image: url('../../assets/litepaper.png');
  right: 222px;
  background-size: 40%;
}

.social-icon:hover {
  background-color: rgb(194, 32, 32);
}

.social-icon {
  position: fixed;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
}

.social-linktree {
  right: unset; 
  left: 10px;
  top: 54px;
}

.social-discord {
  right: unset; 
  left: 10px;
  top: 97px;
}

.social-twitter {
  right: unset; 
  left: 10px;
  top: 140px;
}

.faqs {
  display: none;
}

.litepaper {
  right: unset; 
  left: 10px;
  width: 130px;
  color: #fff;
  font-size: 13px;
  background-image: none;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(159, 30, 30);
}

.bottom-litepaper {
  width: 35px;
  background-image: url('../../assets/litepaper.png');
  font-size: 0px;
  color:rgba(0, 0, 0, 0);
}

.mobile-social-bottom-discord, .mobile-social-bottom-twitter {
  // top: unset;
  // bottom: 10px;
}

.mobile-social-bottom-discord {
  // bottom: 55px;
}


@media only screen and (max-width: 900px) {
  .social-icon {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
  }

  .social-linktree {
    right: unset; 
    left: 100px;
  }

  .social-discord {
    right: unset; 
    left: 10px;
  }

  .social-twitter {
    right: unset; 
    left: 55px;
  }

  .faqs {
    right: unset; 
    left: 10px;
    top: 56px;
  }
  
  .litepaper {
    right: unset; 
    // left: 55px;
    top: 56px;
    width: 125px;
    font-size: 11px;
  }

  .bottom-litepaper {
    background-image: none;
    font-size: 11px;
    color: #fff;
  }

}

// .partners {
//   top: 200px !important;
// }

// .team {
//   top: 200px !important;
// }

.launch-section {
  color: #fff;
  z-index: 1000;
  @media only screen and (max-width: 900px) {
    padding-top: 15px;
  }
  .social-copy {
    font-size: 20px;
    text-align: center;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .discord-button-large {
    position: relative;
    width: auto;
    padding: 12px 25px;
    border-radius: 4px;
    background-color: rgb(194, 32, 32);
    color: #fff;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 900px) {
      margin-bottom: 5px;
      font-size: 16px;
    }

    .discord-icon {
      position: relative;
      width: 22px;
      height: 22px;
      margin-right: 5px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-top: 4px;
      left: -2px;
      background-image: url('../../assets/discord.png');
      @media only screen and (max-width: 900px) {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        margin-top: 1px;
        left: 0px
      }
    }

  }
}

@media only screen and (max-width: 900px) {

  .video-component {
  }

  video {
    position: fixed;
    height: 125%;
    bottom: 0px;
    left: 0px;
  }

}


.discord-cta-container {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  .discord-button-large {
    position: relative;
    top: 0px;
    margin: 0px;
  }
}


.confirm-email-comms {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  margin-top: 0px;
  z-index: 1000;
  @media only screen and (max-width: 900px) {
    margin-top: -50px;
  }

  .tickbox-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    @media only screen and (max-width: 900px) {
      font-size: 11px;
    }

    .tickbox {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      background-color: #fff;
      margin-right: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }

    .tickbox-active {
      background-image: url('../../assets/tick.png');
    }

    span {
      white-space: nowrap;
    }
  }
}


.collections-video, .diner-video {
  video {
    top: -30px !important;
    left: -18% !important;
    width: 135% !important;
    height: 135% !important;
    @media only screen and (max-width: 900px) {
      top: -15px !important;
    }
  }
}