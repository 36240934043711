@import './../../scss/main';

.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 0;
  margin: 0;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 16px;
  overflow: hidden;
  overflow-y: hidden;
}

.education-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100000;

  @media only screen and (max-width: 900px) {
    justify-content: flex-start;
  }

  .education-popup-container {
    position: relative;
    width: 90%;
    height: auto;
    overflow-y: scroll;
    padding-bottom: 40px;
    max-width: 690px;
    background-color: rgb(236, 236, 236);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);

    @media only screen and (max-width: 900px) {
      width: 100%;
      max-width: 100%;
    }

    h2 {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      margin-top: 60px;
      font-size: 30px;
      text-align: center;
      margin-bottom: 30px;
      z-index: 1;
      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }
    }

    span {
      position: relative;
      width: 75%;
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
      @media only screen and (max-width: 900px) {
        max-width: 90%;
        font-size: 10px;
      }

      b, a {
        color: #000;
        font-weight: 500;
        text-decoration: underline;
      }

      b {
        text-decoration: unset;
        color: #c7aa43;
      }

      a:hover {
        text-decoration: underline;
      }

    }

    .close-education-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      background-image: url('../../assets/close-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      cursor: pointer;
      z-index: 10;
    }

    ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      height: auto;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 2;
      margin-top: 0px;
      margin-bottom: 30px;
      max-width: 550px;
      li {
        position: relative;
        width: 90%;
        margin: 0;
        padding: 0;
        height: 40px;
        left: 0px;
        margin-bottom: 10px;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        background-color: #C6B168;
        color: #fff;
        font-size: 18px;
        @media only screen and (max-width: 900px) {
          font-size: 14px;
          height: 30px;
        }
        padding-left: 15px;
        text-align: center;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .expand-learn {
          position: absolute;
          right: 10px;
          height: 20px;
          width: 20px;
          transform: rotate(0deg);
          background-image: url('../../assets/arrow-white.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
        }

        .expand-active {
          transform: rotate(90deg);
        }

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          // font-size: 13px;
          text-align: center;
          display: flex;
          align-items: flex-start;
          padding-left: 15px;
          justify-content: center;
          flex-direction: column;
          background-color: rgb(255, 255, 255) !important;
          left: 0px;

          .cta-learn {
            position: absolute;
            right: 20px;
            width: auto;
            padding: 3px 8px;
            border: 1px solid #000;
            background-color: #fff;
            color: #000;
            border-radius: 4px;
            font-size: 13px;
          }

          @media only screen and (max-width: 900px) {
            .cta-learn {
              position: absolute;
              right: 20px;
              width: auto;
              padding: 2px 6px;
              border: 1px solid #000;
              color: #000;
              border-radius: 4px;
              font-size: 10px;
            }
            font-size: 14px;
          }
        }
        a:hover {
          color: #C6B168;
          .cta-learn {
            border: 1px solid #C6B168;
            color: #C6B168;
          }

        }
      }

      li:hover {
        color: #fff;
      }

    }
  }
}

.mobile-rotate-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 50000;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  @media only screen and (max-width: 900px) {
    @media screen and (orientation:portrait) {
      display: flex;
    }
  }

  .rotate-icon {
    position: relative;
    width: 35px;
    height: 35px;
    margin-left: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/flip-mobile.png');
  }
}

.mobile-rotate-screen-show {
  display: flex;
}


.register-email {
  position: relative;
  margin: 0 auto;
  bottom: 20px;
  width: 100%;
  height: auto;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;

  input {
    position: relative;
    height: 45px;
    width: 90%;
    max-width: 365px;
    border-radius: 4px;
    padding: 5px 20px;
    font-size: 16px;
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;  
    background-color: #000;
    font-weight: 500;
    top: 28px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(128, 103, 103, 0.2);
    color: #fff;
    border: 1px solid #fff;
  }

  .email-button {
    position: relative;
    right: -145px;
    height: 22px;
    width: 60px;
    padding: 5px 15px;
    background-color: rgb(194, 32, 32);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    border: 1px solid rgb(194, 32, 32);
    border-radius: 4px;
    bottom: 18px;
    color: #fff;
    cursor: pointer;
  }

  .input:focus {
    outline: none;
  }

}

.intro-overlay {
  position: fixed;
  z-index: 20000;
  width: 100%;
  height: 100%;
  opacity: 1px;
  transition: 2s;
  background-color: #000;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 900px) {
    transition: 2s;
    top: 0px !important;
  }
  
  .intro-content {
    width: 90%;
    height: 90%;
    max-width: 500px;
    max-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/intro-signature.png');
    margin-top: -50px;
    @media only screen and (max-width: 900px) {
      margin-top: 0px;
      max-width: 350px;
      max-height: 300px;
    }
  }

  .intro-video-lc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;

    video {
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      @media only screen and (max-width: 900px) {
        top: -25%;
        left: -25%;
        width: 150%;
        height: 150%;
        top: -80px;
      }
    }
  }

  .enter-experience-cta {
    position: absolute;
    bottom: -100px;
    z-index: 2;
    width: 60px;
    color: #fff;
    font-size: 13px;
    padding: 12px 25px;
    border-radius: 4px;
    cursor: pointer;
    background-image: none;
    text-align: center;
    line-height: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(159, 30, 30);
    transition: opacity 1s, bottom 1s, background-color 0.2s;
    letter-spacing: 1px;
    @media only screen and (max-width: 900px) {
      padding: 8px 16px;
      font-size: 11px;
    }
  }

  .show-enter-experience-cta {
    opacity: 1;
    bottom: 80px;
    @media only screen and (max-width: 900px) {
      bottom: 30px;
    }
  }

  .enter-experience-cta:hover {
    background-color: rgb(194, 32, 32);
  }

  .loading-spinner {
    position: fixed;
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-image: url('../../assets/loading-red.png');
    bottom: 30px;
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @media only screen and (max-width: 900px) {
      width: 25px;
      height: 25px;
      bottom: 15px;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}